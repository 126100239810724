import React, {useRef, useEffect} from 'react';
import {useLocation, generatePath} from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {InternalLink} from 'components/Links';
import {get} from 'lodash/object';
import {isObject} from 'lodash/lang';
import cleanDeep from 'clean-deep';
import {ROUTE_PATH} from 'constants/routes';
import styles from './DashboardHeaderNav.module.scss';

DashboardHeaderNav.propTypes = {
  items: PropTypes.array.isRequired,
};

DashboardHeaderNav.defaultProps = {
  items: [],
};

function DashboardHeaderNav(props) {
  const {items, startsWith, disabled} = props;
  const location = useLocation();
  const {pathname} = location;

  const sliderRef = useRef(null);

  useEffect(() => {
    const {current: slider} = sliderRef;
    if (slider) {
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('pointerdown', (e) => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('pointerleave', () => {
        isDown = false;
      });
      slider.addEventListener('pointerup', () => {
        isDown = false;
      });
      slider.addEventListener('pointermove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 16;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }, [sliderRef]);

  return (
    <div className={styles['slider']} ref={sliderRef}>
      {items.map((item, index) => {
        const {path, text, params, active, ...rest} = item;

        let routePath = get(ROUTE_PATH, path, '/');

        if (isObject(routePath) && routePath.hasOwnProperty('INDEX')) {
          routePath = routePath['INDEX'];
        }
        let linkTo = generatePath(routePath, cleanDeep(params));

        return (
          <InternalLink
            className={cx(styles['slider-item'], {
              [styles['slider-item--active']]:
                linkTo === pathname ||
                (startsWith && pathname.startsWith(linkTo)) ||
                active,
              [styles['slider-item--inactive']]: disabled,
            })}
            key={index}
            path={path}
            params={params}
            {...rest}
          >
            {text}
          </InternalLink>
        );
      })}
    </div>
  );
}

export default DashboardHeaderNav;
