import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {timestampToLocale} from 'utils/dates';
import {Icon} from 'components/Icons';
import {InternalLink} from 'components/Links';
import {Avatar} from 'components/Avatars';

DashboardActivityItem.propTypes = {
  type: PropTypes.oneOf(['error', 'success']),
  title: PropTypes.any.isRequired,
  icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  iconType: PropTypes.string,
  iconClassName: PropTypes.string,
};

DashboardActivityItem.defaultProps = {
  icon: false,
};

const ICON_TYPE = {
  error: 'exclamation-circle',
  success: 'check-circle',
};

function DashboardActivityItem(props) {
  const {
    type,
    title,
    description,
    children,
    date,
    icon,
    iconType,
    iconClassName,
    className,
    path,
    pathParams,
    companyLogo,
    ...rest
  } = props;

  return (
    <li className={cx('step-item', className)} {...rest}>
      <div className="step-content-wrapper">
        {icon && iconType ? (
          <div className="step-avatar d-flex justify-content-center align-items-center">
            <Icon type={iconType} icon={icon} className={iconClassName} />
          </div>
        ) : companyLogo ? (
          <div className="step-avatar d-flex justify-content-center align-items-center">
            <Avatar size="sm" image={companyLogo} alt={companyLogo} />
          </div>
        ) : (
          <span className="step-icon step-icon-pseudo step-icon-soft-dark" />
        )}

        <div className="step-content">
          <h5 className="mb-1">
            {path ? (
              <InternalLink path={path} params={pathParams}>
                {title}
              </InternalLink>
            ) : (
              <p className="text-dark m-0">{title}</p>
            )}
          </h5>

          {description && (
            <p className="font-size-sm mb-1">
              {description}

              {type && (
                <Icon
                  type="fa"
                  icon={ICON_TYPE[type]}
                  className={cx('ml-2', {
                    'text-danger': type === 'error',
                    'text-success': type === 'success',
                  })}
                />
              )}
            </p>
          )}

          {children}

          {date && (
            <small className="text-muted text-uppercase">
              {timestampToLocale(date, true)}
            </small>
          )}
        </div>
      </div>
    </li>
  );
}

export default DashboardActivityItem;
