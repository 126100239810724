import React from 'react';
import PropTypes from 'prop-types';

DashboardDescriptionList.propTypes = {
  title: PropTypes.any,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any,
      content: PropTypes.any,
    })
  ),
};

function DashboardDescriptionList(props) {
  const {title, items, rest} = props;
  return (
    <>
      {title && <small className="card-subtitle mb-3">{title}</small>}

      <ul className="list-unstyled list-unstyled-py-3 mb-5" {...rest}>
        {items.map((item, index) => {
          const {title, content} = item;

          return (
            <li key={index}>
              <dl className="row">
                <dt className="col-12 col-md-4 col-xl-3 font-weight-normal text-body">
                  {title}
                </dt>
                <dd className="col mb-0 text-dark">{content}</dd>
              </dl>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DashboardDescriptionList;
