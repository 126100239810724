import http from 'utils/http';

export const getSettings = () => {
  return http('GET', 'settings');
};

export const updateSettings = (data) => {
  return http('PUT', 'settings', data);
};

/* Notification Settings API */

export const getNotification = () => {
  return http('GET', 'account/notifications/settings');
};

export const updateNotification = (id, data) => {
  return http('PUT', `account/notifications/settings/${id}`, data);
};

export const enableEmergencyPlug = () => {
  return http('PUT', '/emergency-plug', {emergency_plug: true});
};

export const getEmergencyPlugStatus = () => {
  return http('GET', '/emergency-plug/status');
};

export const getInactivityTimeout = () => {
  return http('GET', '/inactivity-timeout');
};

export const updateInactivityTimeout = (data) => {
  return http('PUT', '/inactivity-timeout', data);
};

/* CB Data/Certification Sync APIs */

// [AB] - CB Data Sync
export const getDataSyncCBSettings = () => {
  return http('GET', '/data-sync/cb/settings');
};

export const updateDataSyncCBSettings = (data) => {
  return http('PUT', '/data-sync/cb/settings', data);
};

export const postCBDataSync = () => {
  return http('POST', '/data-sync/cb');
};

// [CB] - Cert Data Sync
export const getDataSyncCertSettings = () => {
  return http('GET', '/data-sync/cert/settings');
};

export const updateDataSyncCertSettings = (data) => {
  return http('PUT', '/data-sync/cert/settings', data);
};

export const postCertDataSync = () => {
  return http('POST', '/data-sync/cert');
};

// [AB][CB] - Data Sync Status
export const getDataSyncStatus = () => {
  return http('GET', '/data-sync/status');
};
