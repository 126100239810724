import {Icon} from 'components/Icons';
import {TextLoading} from 'components/Loadings';
import {ROUTE_PATH} from 'constants/routes';
import {includes} from 'lodash';
import React from 'react';
import {useLocation} from 'react-router';
import {Label} from 'reactstrap';

function FieldPasswordBreaches(props) {
  const {pathname} = useLocation();
  const loginPath = ROUTE_PATH.LOG_IN;
  const inLogin = includes(loginPath, pathname);

  const {isLoading, inBreached} = props;
  return (
    <>
      <Label className="mb-2">Exposed in data breaches?</Label>
      <div className="mb-5" style={{fontSize: '13px'}}>
        {!isLoading ? (
          <>
            <Icon
              type="fa"
              size="xs"
              icon={inBreached ? 'times' : 'check'}
              className={
                inBreached && !isLoading ? 'text-danger' : 'text-success'
              }
            />{' '}
            <span className="ml-1">
              {inBreached && !isLoading
                ? `Password has appeared in a data leak${
                    !inLogin
                      ? `, which puts this account at
            high risk of compromise. Please choose a different password.`
                      : '.'
                  }`
                : 'No leaks found'}
            </span>
          </>
        ) : (
          <TextLoading isLoading />
        )}
      </div>
    </>
  );
}

export default FieldPasswordBreaches;
