import React from 'react';

import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap';
import useCaptchaModal from 'hooks/useCaptchaModal';
import IPCaptcha from './IPCaptcha';
import {CaptchaAlternativeLink} from './index';
import {UKAS_TITLE} from 'constants/environment';

CaptchaModal.defaultProps = {
  children: () => {}, // for captcha verification without children
};

function CaptchaModal(props) {
  const {initialCheck, children, onClose} = props;
  const {isOpen, isVerified, handleClose, verifyCaptcha} = useCaptchaModal(
    initialCheck,
    onClose
  );

  return (
    <React.Fragment>
      {isVerified && children({isVerified: isVerified})}
      <Modal centered isOpen={isOpen}>
        <ModalHeader>Security Verification</ModalHeader>
        <ModalBody>
          <p>
            Before you use {UKAS_TITLE}, we need to confirm you are a human.
          </p>
          <div className="d-flex justify-content-center">
            <IPCaptcha onVerify={verifyCaptcha} />
          </div>

          <div className="mt-4 text-center">
            <CaptchaAlternativeLink />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClose} color="white">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default CaptchaModal;
