import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

IconFA.propTypes = {
  size: PropTypes.oneOf(['xs', 'lg', '6x']),
  name: PropTypes.any.isRequired,
  light: PropTypes.bool,
  regular: PropTypes.bool,
  brand: PropTypes.bool,
  fixedWidth: PropTypes.bool,
};

IconFA.defaultProps = {
  light: false,
  regular: false,
  fixedWidth: false,
};

function IconFA(props) {
  const {size, name, light, regular, brand, ...rest} = props;
  const iconStyle = light ? 'fal' : regular ? 'far' : brand ? 'fab' : 'fas';
  return <FontAwesomeIcon size={size} icon={[iconStyle, name]} {...rest} />;
}

export default IconFA;
