import {FormLabel} from 'components/Forms';
import React from 'react';
import {Col, FormGroup, Label} from 'reactstrap';

DashboardFormField.propTypes = {};

DashboardFormField.defaultProps = {
  row: true,
  required: false,
};

function DashboardFormField(props) {
  const {
    row,
    label,
    children,
    grid = row ? {sm: 12, lg: 4, xl: 3} : undefined,
    customGrid,
    className,
    required,
  } = props;

  const _grid = customGrid ? customGrid : grid;

  return (
    <FormGroup row={row} className={className}>
      {required && (
        <FormLabel labelFor={label} text={label} required {..._grid} />
      )}
      {!required && <Label {..._grid}>{label}</Label>}

      <Col>{children}</Col>
    </FormGroup>
  );
}

export default DashboardFormField;
