import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash/lang';

import {useCaptchaCode} from 'contexts/CaptchaContext';

import HCaptcha from './HCaptcha';
import ReCaptcha from './ReCaptcha';

IPCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func,
};

function IPCaptcha(props) {
  const {onVerify, onExpire} = props;
  const {captchaCode} = useCaptchaCode();

  function handleLoad() {
    // nothing yet
  }

  const [currentCode] = useState(captchaCode);

  useEffect(() => {
    if (!isEqual(captchaCode, currentCode)) {
      window.location.reload();
    }
  }, [captchaCode]);

  return (
    <>
      {currentCode === 'h' ? (
        <HCaptcha
          onVerify={(response) => onVerify(response, 'hcaptcha')}
          onExpire={onExpire}
          onLoad={handleLoad}
        />
      ) : (
        <ReCaptcha
          onVerify={(response) => onVerify(response, 'recaptcha')}
          onExpire={onExpire}
          onLoad={handleLoad}
        />
      )}
    </>
  );
}

export default IPCaptcha;
