import cleanDeep from 'clean-deep';
import {CardContentFooter} from 'components/Cards';
import React from 'react';
import {Button} from 'reactstrap';

DatePickerActions.defaultProps = {
  onApply: () => {},
  onCancel: () => {},
  onClearDates: () => {},
  range: [],
};

function DatePickerActions(props) {
  const {
    onCancel,
    onApply,
    range,
    isDisabled: isRangeDisabled,
    acceptSameDate,
  } = props;

  const isDisabled = acceptSameDate
    ? cleanDeep(range).length === 0
    : cleanDeep(range).length !== 2;

  return (
    <CardContentFooter
      className="d-flex justify-content-end"
      style={{gap: '1rem'}}
    >
      <Button color="white" type="button" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        color="primary"
        disabled={isDisabled || isRangeDisabled}
        onClick={() => onApply()}
      >
        Apply
      </Button>
    </CardContentFooter>
  );
}

export default DatePickerActions;
