import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {isEmpty, isString} from 'lodash/lang';
import {first} from 'lodash/array';

import {fileToImageUrl} from 'utils/images';

//
// Usage
// https://htmlstream.com/front-dashboard/documentation/avatars.html

Avatar.propTypes = {
  //image: PropTypes.string,
  alt: PropTypes.any,
  size: PropTypes.oneOf(['xxl', 'xl', 'lg', 'sm', 'xs']),
  soft: PropTypes.bool,
  border: PropTypes.bool,
  dashed: PropTypes.bool,
  centered: PropTypes.bool,
};

Avatar.defaultProps = {
  tag: 'span',
  soft: true,
  color: 'dark',
  circle: true,
  bordered: true,
  dashed: false,
  centered: false,
};

function Avatar(props) {
  const {
    tag,
    size,
    color,
    soft,
    circle,
    bordered,
    dashed,
    centered,
    className,
    image,
    alt,
    children,
    ...rest
  } = props;

  const _colors = [soft && 'soft', color && color].filter((v) => v);

  const _className = cx(
    'avatar',
    {
      'avatar-circle': circle,
      [`avatar-${size}`]: size,
      [['avatar', ..._colors].join('-')]: !isEmpty(_colors),
      'avatar-border-lg': bordered,
      'avatar-border-dashed': dashed,
      'avatar-centered': centered,
    },
    className
  );

  const src = fileToImageUrl(image, 'lg');

  return React.createElement(
    tag,
    {className: _className, ...rest},
    <>
      {src && (
        <img
          className="avatar-img"
          src={src}
          alt={isString(alt) ? alt : undefined}
        />
      )}
      {!src && alt && (
        <span className="avatar-initials">
          {isString(alt)
            ? alt
                .split(' ', 2)
                .map((v) => first(v))
                .join('')
            : alt}
        </span>
      )}

      {children}
    </>
  );
}

export default Avatar;
