import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import GoogleRecaptcha from 'react-google-recaptcha';
import {RECAPTCHA_SITEKEY} from 'constants/environment';

// useRecaptchaNet: If google.com is blocked,
// you can set useRecaptchaNet to true so that the component uses recaptcha.net instead.
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

const grecaptchaObject = window.grecaptcha; // You must provide access to the google grecaptcha object.

ReCaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onExpire: PropTypes.func,
};

function ReCaptcha(props) {
  const {onVerify, onExpire, onLoad} = props;
  const captchaRef = createRef();

  function handleVerify(token) {
    onVerify(token);
  }

  function handleError() {
    alert('Error has been occurred. Please try again.');
    //captchaRef.current.reset();
  }

  function handleExpire() {
    alert('Captcha has expired. Please try again.');
    captchaRef.current.reset();
    onExpire && onExpire();
  }

  function handleLoad({loaded}) {
    if (loaded) {
      onLoad(false); // isLoading false
    }
  }

  return (
    <GoogleRecaptcha
      size="normal"
      render="explicit"
      ref={captchaRef}
      sitekey={RECAPTCHA_SITEKEY}
      onChange={handleVerify}
      onExpired={handleExpire}
      onErrored={handleError}
      grecaptcha={grecaptchaObject}
      asyncScriptOnLoad={handleLoad} // optional callback when the google recaptcha script has been loaded
    />
  );
}

export default ReCaptcha;
