import http, {get, makeSearchParams} from 'utils/http';

export const getStandardsBodyRelated = (queries) => {
  return get(`/standards/body-related`, queries);
};

// UNUSED
export const getStandardsLibrary = (queries) => {
  return http('GET', '/standards', makeSearchParams(queries));
};

export const getStandard = (id) => {
  return http('GET', `/standards/${id}`);
};

export const addStandard = (data) => {
  return http('POST', `/standards`, data);
};

export const putStandard = (id, data) => {
  return http('PUT', `/standards/${id}`, data);
};

export const delStandard = (id) => {
  return http('DELETE', `/standards/${id}`);
};

export const getStandards = (queries) => {
  return http('GET', `/standards/list`, makeSearchParams(queries));
};

// UNUSED
export const getStandardsCount = () => {
  return get(`/standards/count`);
};

// SCHEMES API

export const getSchemes = (queries) => {
  return http('GET', `/schemes`, makeSearchParams(queries));
};

// UNUSED
export const getSchemesCount = () => {
  return get(`/schemes/count`);
};

export const getScheme = (id) => {
  return http('GET', `/schemes/${id}`);
};

export const editScheme = (id, data) => {
  return http('PUT', `/schemes/${id}`, data);
};

export const deleteScheme = (id) => {
  return http('DELETE', `/schemes/${id}`);
};

export const addScheme = (data) => {
  return http('POST', `/schemes`, data);
};

// Get Public Standard use in CB Search

// UNUSED
export const getPublicStandards = (queries) => {
  return http('GET', `/g/standards/search-by-name`, makeSearchParams(queries));
};

export const getStandardsVersionList = (queries) => {
  return http('GET', '/standards/version/list', makeSearchParams(queries));
};

export const getCBStandardsList = (queries, cbId) => {
  return http('GET', `/cb/${cbId}/standards-list`, makeSearchParams(queries));
};
