import 'styles/main.scss';

// Packages
import React, {useEffect} from 'react';
import {BrowserRouter as Router, useLocation} from 'react-router-dom';

// constants
import routes from 'routes';
import {ROUTER_SCROLL_TYPES} from 'configs';
import renderRoutes from 'utils/render-routes';

// contexts/hooks
import useBSID from 'hooks/useBSID';

// components
import {Page, PageHelmet} from 'components/Pages';
import {CookieConsent} from 'components/CookieConsent';

import './AppIcons';
import AppProviders from './AppProviders';

function ScrollToTop() {
  const {
    pathname,
    search,
    //hash,
    state,
  } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (!state || state.scroll !== ROUTER_SCROLL_TYPES.stay) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }, [pathname, search, state]);

  /*useEffect(() => {
    if (hash) {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');

        console.log(id);

        const element = document.getElementById(hash);

        console.log('element', element);

        if (element)
          element.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 10);
    }
  }, [hash]);*/

  return null;
}

function App() {
  const {isLoading} = useBSID(); // init BSID
  if (isLoading) return 'loading...';

  return (
    <div className="App">
      <PageHelmet
        title={process.env.REACT_APP_META_TITLE}
        description={process.env.REACT_APP_META_DESCRIPTION}
      >
        <link rel="canonical" href="https://certcheck.ukas.com" />
        <meta name="robots" content="index, follow" />
      </PageHelmet>

      <Router>
        <ScrollToTop />

        <AppProviders>
          <Page>{renderRoutes(routes)}</Page>
          <CookieConsent />
        </AppProviders>
      </Router>
    </div>
  );
}

export default App;
