import React from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import {IMAGE_SUCCESS} from 'configs';
import {Col, Row, Button} from 'reactstrap';
import {Icon} from 'components/Icons';
import {InternalLink} from 'components/Links';

// This one should load the image in URL
DashboardResultSuccess.propTypes = {
  message: PropTypes.string,
  backMessage: PropTypes.string,
  backTo: PropTypes.string,
  continueMessage: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
};

const defaultProps = {
  message: 'Success!',
  backMessage: 'Back to dashboard',
  backTo: '/dashboard',
};

function DashboardResultSuccess({
  message,
  backMessage,
  backTo,
  continueMessage,
  onContinue,
}) {
  const params = useParams();

  return (
    <Row className="justify-content-sm-center text-center py-10">
      <Col sm={7} md={5}>
        <img
          src={IMAGE_SUCCESS}
          alt="Success"
          className="img-fluid mb-5"
          style={{maxWidth: '21rem'}}
        />

        <div className="mt-3 text-center">
          <h1>Successful!</h1>
          <p>{message}</p>
        </div>

        <div>
          <Button
            tag={InternalLink}
            path={backTo}
            params={params}
            color="white"
            className="mr-3"
          >
            {backMessage}
          </Button>

          {onContinue && (
            <Button onClick={onContinue} color="primary">
              <Icon type="fa" append={continueMessage} icon="plus" />
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
}

DashboardResultSuccess.defaultProps = defaultProps;

export default DashboardResultSuccess;
