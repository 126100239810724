import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {lowerCase} from 'lodash/string';
import FilterColumn from './FilterColumn';
import cx from 'classnames';

FilterColumnNoQuery.propTypes = {
  toggle: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

function FilterColumnNoQuery({
  value,
  children,
  toggle,
  selected,
  onChange,
  search = {},
  isSticky,
  disabled,
  ...rest
}) {
  const {order_by, order} = search;
  const [style, setStyle] = useState(`sorting_${lowerCase(order)}`);
  // '' = no sort 'asc' = ascending 'desc' descending

  function handleChange() {
    toggle(value);

    if (selected !== value) {
      setStyle('sorting_asc');
      onChange({order_by: value, order: 'DESC'});
    } else {
      if (!order) {
        setStyle('sorting_asc');
        onChange({order_by: value, order: 'DESC'});
      } else if (order === 'DESC') {
        setStyle('sorting_desc');
        onChange({order_by: value, order: 'ASC'});
      } else if (order === 'ASC') {
        setStyle('sorting');
        onChange({order_by: '', order: ''});
      }
    }
  }

  return (
    <FilterColumn
      className={cx(
        {sticky: isSticky, 'top-0': isSticky},
        order_by === value ? style : 'sorting'
      )}
      handleChange={() => {
        !disabled && handleChange();
      }}
      {...rest}
    >
      {children}
    </FilterColumn>
  );
}

export default FilterColumnNoQuery;
