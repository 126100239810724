import React from 'react';
import {useParams} from 'react-router-dom';
import {truncate} from 'lodash/string';
import {isString} from 'lodash/lang';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {TextLoading} from 'components/Loadings';

function DashboardBreadcrumb(props) {
  const {items} = props;
  const params = useParams();

  return (
    <Breadcrumb tag="nav" listTag="ol" listClassName="breadcrumb-no-gutter">
      {(items || []).map((item, index) => {
        const {label, path, isLoading, active} = item;

        const breadcrumbLabel = isLoading ? (
          <TextLoading isLoading />
        ) : isString(label) ? (
          truncate(label, {length: 30})
        ) : (
          label
        );

        return active ? (
          <BreadcrumbItem key={index} active>
            {breadcrumbLabel}
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={index}>
            <InternalLink
              className="breadcrumb-link"
              path={path}
              params={params}
            >
              {breadcrumbLabel}
            </InternalLink>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}

export default DashboardBreadcrumb;
