import React from 'react';
import {Row, Col, Label} from 'reactstrap';
import {
  SearchFilterLimit,
  SearchFilterPagination,
} from 'components/SearchFilters';
import {PaginationStateful} from 'components/Paginations';
import {LimitStateful} from 'components/Limits';

DashboardPagination.defaultProps = {
  showLimit: true,
  isStateful: false,
};

function DashboardPagination(props) {
  const {meta = {}, query, update, showLimit, isStateful} = props;
  const {total_rows, initialLimit = 0} = meta;
  const check = isStateful ? initialLimit : 20;

  return (
    <Row>
      {!showLimit && <Col />}
      {showLimit && (
        <Col>
          {total_rows >= check ? (
            isStateful ? (
              <LimitStateful
                initialLimit={initialLimit}
                meta={meta}
                update={update}
              />
            ) : (
              <SearchFilterLimit meta={meta} />
            )
          ) : (
            <Label>{`Showing: ${total_rows || 0} item/s`}</Label>
          )}
        </Col>
      )}

      <Col className="col-auto" style={{overflowY: 'auto'}}>
        {isStateful ? (
          <PaginationStateful meta={meta} query={query} update={update} />
        ) : (
          <SearchFilterPagination meta={meta} />
        )}
      </Col>
    </Row>
  );
}

export default DashboardPagination;
