import React from 'react';
import cx from 'classnames';

DashboardTitle.defaultProps = {
  tag: 'h1',
};

function DashboardTitle(props) {
  const {tag, className, children, ...rest} = props;

  return React.createElement(
    tag,
    {
      className: cx('page-header-title', className),
      ...rest,
    },
    children
  );
}

export default DashboardTitle;
