import styles from './DashboardTable.module.scss';
import React from 'react';
import cx from 'classnames';
import {Table} from 'reactstrap';
import {SpinnerLoading} from 'components/Loadings';

DashboardTable.defaultProps = {
  isLoading: false,
  alignMiddle: true,
  borderless: true,
  hover: true,
  fixed: false, // table-layout: fixed
  collapse: false,
  verticalMiddle: false,
  isSpinner: false,
};

function DashboardTable(props) {
  const {
    isLoading,
    alignMiddle,
    borderless,
    hover,
    className,
    fixed,
    collapse,
    verticalMiddle,
    children,
    size,
    style,
  } = props;

  const _className = cx(
    styles['dashboard-table'],
    'table-thead-bordered table-nowrap card-table',
    {
      'table-align-middle': alignMiddle,
      [styles['dashboard-table--fixed']]: fixed,
      [styles['dashboard-table--collapse']]: collapse,
      [styles['dashboard-table--vertical-align-middle']]: verticalMiddle,
    },
    className
  );

  return (
    <div
      className="table-responsive datatable-custom"
      style={{minHeight: isLoading ? '500px' : '200px', ...style}}
    >
      {isLoading && <SpinnerLoading isLoading position="absolute" />}

      <Table
        className={_className}
        borderless={borderless}
        hover={hover}
        size={size}
      >
        {children}
      </Table>
    </div>
  );
}

export default DashboardTable;
