import http, {post, put, get} from 'utils/http';

//
// UKAS auth

/**
 * Log in
 * @param data
 * @returns {auth}
 */
// UNUSED
export const postAuthLogin = (data) => {
  const {email, password, remember, secret, eg_code} = data;
  return post('auth/login', {email, password, remember, secret, eg_code});
};

/**
 * Verify Login Captcha
 * @param data: captcha_session_id
 * @returns captcha_session_id
 */
export const postAuthCaptchaVerify = (data) => {
  return post('auth/captcha/verify', data);
};

/**
 * Verify Login Email
 * @param data: email, captcha_session_id
 * @returns email_session_id
 */
export const postAuthEmail = (data) => {
  return post('auth/email', data);
};

/**
 * Verify Login Password
 * @param data: password, email_session_id
 * @returns session_id or mfa_session_id, account_otp_enabled, account_email_guard
 */
export const postAuthPassword = (data) => {
  return post('auth/password', data);
};

/**
 * Check Password Vulnerability
 * @param data: password
 * @returns password_breached: true || false
 */
export const postCheckPassword = (data) => {
  return post('/auth/password/check', data);
};

/**
 * 2FA Send Email Guard code
 * @param data: mfa_session_id
 * @returns sent, mfa_session_id
 */
export const postAuthEmailGuardSend = (data) => {
  return post('auth/email-guard/send', data);
};

/**
 * 2FA Verify Email Guard code
 * @param data: mfa_session_id, email_guard_code
 * @returns session_id
 */
export const postAuthEmailGuardVerify = (data) => {
  return post('auth/email-guard/verify', data);
};

/**
 * 2FA Verify Authenticator code
 * @param data: token
 * @returns session_id
 */
export const postAuthAuthenticator = (data) => {
  return post('auth/authenticator/verify', data);
};

/**
 * 2FA Verify Authenticator code
 * @param data: token
 * @returns session_id
 */
// UNUSED
export const postAuthGoogle2FAVerify = (data) => {
  return post('auth/google-2fa/verify', data);
};

/**
 * Sign up
 * @param data
 * @returns {Promise<*>}
 */
export const postAuthRegistration = (data) => {
  return post('auth/registration', data);
};

/**
 * Update confirmation
 * @param data: {eg_code}
 * @returns {Promise<*>}
 */
export const putAuthConfirmation = (data) => {
  return put(`auth/confirmation`, data);
};

/**
 * Log out
 * @returns {Promise<unknown>}
 */
export const postAuthLogout = () => {
  return post('auth/logout');
};

/**
 * Send Reset Password Link
 * @param data
 * @returns {Promise<*>}
 */
export const postAuthForgot = (data) => {
  return post('auth/forgot', data);
};

/**
 * Reset Password
 * @param data
 * @returns {Promise<*>}
 */
export const postAuthReset = (data) => {
  return post('auth/reset', data);
};

/**
 * Verify reset token is correct
 * @param token
 * @returns {Promise<*>}
 */
export const getAuthResetByToken = (token) => {
  return get(`auth/reset/${token}`);
};

/**
 * Validate User Invitation Token
 * @param token
 * @returns {*}
 */
export const getAuthAcceptByToken = (token) => {
  return get(`auth/accept/${token}`);
};

/**
 * Accept User Invitation
 * @param data
 * @returns {*}
 */
export const postAuthAccept = (data) => {
  // Data includes the token.
  return post(`auth/accept`, data);
};

/**
 * Validate Token and get company data
 * @param token
 * @returns {Promise<unknown>}
 */
export const getAuthActivateByToken = (token) => {
  return get(`auth/activate/${token}`);
};

/**
 * Activate company by token
 * @param token
 * @param data
 * @returns {*}
 */
export const postAuthActivateByToken = (token, data) => {
  return post(`auth/activate/${token}`, data);
};

/**
 * Activate Company by company ID
 * @param companyId
 * @param data
 * @returns {*}
 */
export const postAuthActivateCompanyById = (companyId, data) => {
  return post(`auth/activate/c/${companyId}`, data);
};

//
//
//
// Existing from IAF

export const getAuthSession = (sessionId) => {
  return http('GET', `auth/session/${sessionId}`);
};

export const logout = () => {
  return http('POST', 'auth/logout');
};

export const forgot = (params) => {
  return http('POST', 'auth/forgot', params);
};

export const reset = (params) => {
  return http('POST', 'auth/reset', params);
};

// UNUSED
export const getAuthResetToken = (token) => {
  return http('GET', `auth/reset/${token}`);
};

export const confirmRegistration = (token) => {
  return http('PUT', `auth/confirmation/${token}`);
};

/**
 * Send Activation Email to CB
 * @param cbId
 * @returns {*}
 */
// UNUSED
export const sendCertificationBodyActivation = (cbId) => {
  return http('POST', `activation/cb/${cbId}`);
};

/**
 * Send Activation Email to Company (CE)
 * @param companyId
 * @returns {*}
 */
// UNUSED
export const sendBranchActivation = (companyId) => {
  return http('POST', `activation/branch/${companyId}`);
};

export const sendBulkActivation = () => {
  return http('POST', `activation/ce/bulk`);
};

// UNUSED
export const getActivationHistory = (companyType, companyId, queries) => {
  return http('GET', `activation/branch/${companyId}/${companyType}`, queries);
};

// Verify Captcha
export const postAuthCaptcha = (captchaType, data) => {
  return http('POST', `auth/captcha?type=${captchaType}`, data);
};

// UNUSED
export const resendAuthEmailGuard = (data) => {
  return http('POST', `/auth/2fa/eg-resend`, data);
};
