import styles from './DashboardButton.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Button, Spinner} from 'reactstrap';

DashboardButton.propTypes = {
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

DashboardButton.defaultProps = {
  isLoading: false,
  type: 'button',
  tag: Button,
  color: 'primary',
};

function DashboardButton(props) {
  const {tag, color, isLoading, disabled, className, children, ...rest} = props;

  return React.createElement(
    tag,
    {
      color,
      className: cx(styles['button'], className),
      disabled: isLoading || disabled,
      ...rest,
    },
    <div className={styles['button__inner']}>
      <Spinner
        className={styles['button__spinner']}
        size="sm"
        style={{opacity: isLoading ? 100 : 0}}
      />
      <span style={{opacity: isLoading ? 0 : 100}}>{children}</span>
    </div>
  );
}

export default DashboardButton;
