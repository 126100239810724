import http, {get, post, put, makeSearchParams} from 'utils/http';

export const getAccount = () => {
  return http('GET', `account`);
};

export const updateAccount = (data) => {
  return http('PUT', `account`, data);
};

// UNUSED
export const updateAccountConsent = (data) => {
  return http('PUT', `account/consent`, data);
};

export const changePassword = (data) => {
  return http('PUT', `account/password`, data);
};

export const updateAccountPasswordSkip = () => {
  return put(`/account/password/skip`);
};

export const getAccountCompany = () => {
  return http('GET', `settings-company`);
};

export const updateAccountCompany = (data) => {
  return http('PUT', `settings-company`, data);
};

export const sendConfirmEmail = () => {
  return http('POST', `account/resend-confirm-email`);
};

export const getAccountActivities = (queries) => {
  return get('account/activity', queries);
};

// UNUSED
export const signOutActivities = (token) => {
  return http('POST', `account/activity/sign_out?token=${token}`);
};

/**
 * Get notification items
 * @param queries: nextKey
 *
 */
export const getAccountNotifications = (queries) => {
  return http('GET', `account/notifications`, makeSearchParams(queries));
};

export const getAccountNotificationCount = () => {
  return http('GET', `account/notifications/count`);
};

export const updateAccountNotification = (data) => {
  return http('PUT', `account/notifications`, data);
};

export const updateAccountNotificationMarkAllRead = (type) => {
  return http('PUT', `account/notifications/all?filter[type]=${type}`);
};

export const deleteAccountNotification = (id) => {
  return http('DELETE', `account/notifications/${id}`);
};

export const updateAccountNotificationTurnOff = (data) => {
  return http('PUT', 'account/notifications/off', data);
};

/**
 * Get Account OTP Subscribe
 * Using returned data, generate QR code
 * @returns {company, email, key}
 */
export const postAccountOTPSubscribe = () => {
  return post('account/otp/subscribe');
};

/**
 * Verify OTP code
 * @param data
 * @returns {Promise<*>}
 */
export const postAccountOTPVerify = (data) => {
  return post('account/otp/verify', data);
};

export const unsubscribeAccountOTP = (data) => {
  return http('DELETE', 'account/otp/unsubscribe', data);
};

// Watchlist Api
export const getAccountWatchList = (data) => {
  return http('GET', `account/watchlist`, makeSearchParams(data));
};

export const getAccountWatchListByCert = (certId, data) => {
  return http(
    'GET',
    `account/watchlist/${certId}/cert`,
    makeSearchParams(data)
  );
};

export const addAccountWatchList = (certId) => {
  return http('POST', `account/watchlist`, {company_certifications_id: certId});
};

export const deleteAccountWatchListById = (certId) => {
  return http('DELETE', `account/watchlist/${certId}`);
};

export const deleteAccountWatchList = (data) => {
  return http('DELETE', `account/watchlist`, data);
};

export const updateAccountEmailGuard = (data) => {
  return http('PUT', `account/email-guard`, data);
};

export const accountSendEmailGuard = (data) => {
  return http('POST', 'account/send-email-guard', data);
};

export const accountVerifyEmailGuard = (data) => {
  return http('POST', 'account/verify-email-guard', data);
};

// Search History API

export const getAccountHistory = (data) => {
  return http('GET', `account/history`, makeSearchParams(data));
};

export const getAccountHistoryCount = (query) => {
  return http('GET', `account/history/count`, makeSearchParams(query));
};

// UNUSED
export const getAccountByIdCert = (id) => {
  return http('GET', `account/history/${id}/cert`);
};

export const deleteAccountHistoryById = (id) => {
  return http('DELETE', `account/history/${id}`);
};

// delete search history bulk
// export const deleteAccountWatchList = (data) => {
//   return http('DELETE', `account/watchlist`, data);
// };

// Multiple Delete History
export const deleteAccountHistory = (data) => {
  return http('DELETE', `account/history`, data);
};

export const getAccountDevice = () => {
  return get(`/account/device`);
};

export const getAccountDeviceById = (deviceId) => {
  return get(`/account/device/${deviceId}`);
};

export const getAccountDeviceByIdActivities = (deviceId) => {
  return get(`/account/device/${deviceId}/activities`);
};

export const getAccountDeviceByIdForget = (deviceId) => {
  return post(`/account/device/${deviceId}/forget`);
};

export const getAccountDeviceByIdLogout = (deviceId) => {
  return post(`/account/device/${deviceId}/logout`);
};

export const putAccountDeviceByIdValidity = (deviceId, data) => {
  return put(`/account/device/${deviceId}/validity`, data);
};
