import React, {useState, useEffect} from 'react';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import {get, assign} from 'lodash/object';
import {isEmpty} from 'lodash/lang';

import useAlert from 'hooks/useAlert';
import {fileToImageUrl, dataURItoBlob, returnFileSize} from 'utils/images';
import {
  validateImage,
  IMAGES_FEEDBACK,
  ONLY_ACCEPTED_IMAGE,
} from 'constants/images';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
} from 'reactstrap';
import {Icon} from 'components/Icons';

const initialSettings = {
  position: {x: 0.5, y: 0.5},
  scale: 1,
  rotate: 0,
  border: 10,
  width: 160,
  height: 160,
  borderRadius: 0,
};

FormImageEditorModal.defaultProps = {
  submitText: 'Save changes',
};

function FormImageEditorModal({
  header,
  isOpen,
  toggle,
  isLoading,
  defaultImage,
  //allowZoomOut,
  isSubmitting,
  onSubmit,
  callback,
  fileSizeLimit,
  fileDimension,
  submitText,
  additionalSettings,
  deleteText,
  deleteConfirmText,
  ...rest
}) {
  const onlyAcceptedImage = ONLY_ACCEPTED_IMAGE.all;
  const {alertError, alertConfirm, alertByError} = useAlert();

  const [editor, setEditor] = useState({});
  const [image, setImage] = useState('');
  const [settings, setSettings] = useState(
    !!additionalSettings
      ? {...initialSettings, ...additionalSettings}
      : initialSettings
  );
  const [isNewImage, setIsNewImage] = useState(false);

  const {position, scale, rotate, border, width, height, borderRadius} =
    settings;

  const supportedImages = Object.keys(ONLY_ACCEPTED_IMAGE).map((v, key) =>
    v === 'image' || v === 'all'
      ? ''
      : ' ' + v.toUpperCase() + (key === 3 ? ' &' : key === 4 ? '' : ', ')
  );

  useEffect(() => {
    if (isOpen) {
      if (!isEmpty(defaultImage) && fileToImageUrl(defaultImage)) {
        // When re-edit the existing image, call original image.
        const _image = fileToImageUrl(defaultImage, 'origin');
        setImage(_image); // https
        setIsNewImage(true);
      } else {
        setImage('');
      }
    } else {
      setSettings(
        !!additionalSettings
          ? {...initialSettings, ...additionalSettings}
          : initialSettings
      );
    }
  }, [isOpen]);

  async function handleNewImage(e) {
    const target = e.target;
    const image = target.files[0];
    const isValid = image ? validateImage(image.type) : false;

    if (isValid) {
      setIsNewImage(true);
      setImage(image);
    } else {
      const result = Object.keys(ONLY_ACCEPTED_IMAGE).find(
        (key) => ONLY_ACCEPTED_IMAGE[key] === onlyAcceptedImage
      );

      await alertError({
        title: 'Invalid File Type',
        text: IMAGES_FEEDBACK[result],
      });

      target.value = ''; // Reset File Input
    }
  }

  function updateSettings(obj) {
    setSettings(assign({}, settings, obj));
  }

  function handleScale(e) {
    //setScale(parseFloat(e.target.value));
    updateSettings({scale: parseFloat(e.target.value)});
  }

  function handleRotateLeft(e) {
    e.preventDefault();
    //setRotate(rotate - 90);
    updateSettings({rotate: rotate - 90});
  }

  function handleRotateRight(e) {
    e.preventDefault();
    //setRotate(rotate + 90);
    updateSettings({rotate: rotate + 90});
  }

  function handlePositionChange(position) {
    //setPosition(position);
    updateSettings({position: position});
  }

  function handleDrop(acceptedFiles) {
    setImage(acceptedFiles[0]);
  }

  function handleRef(editor) {
    if (editor) setEditor(editor);
  }

  async function handleSave(e) {
    e.preventDefault();
    // Original file name from image.

    if (!image && isEmpty(defaultImage) && !fileToImageUrl(defaultImage)) {
      await alertError({
        text: `Please upload a image to save.`,
      });

      return;
    }

    // File size
    const fileSize = returnFileSize(image.size);

    if (fileSizeLimit && fileSizeLimit <= fileSize) {
      await alertError({
        text: `Cannot send file size that is greater than ${fileSizeLimit} MB`,
      });
      return;
    }

    const imageName = get(image, 'name', 'image.png');

    // Get Data URL and convert to PNG.
    let dataURL;
    if (image === '') {
      dataURL = editor.getImageScaledToCanvas().toDataURL('image/png');
    } else {
      dataURL = editor.getImage().toDataURL('image/png');
    }
    const blob = dataURItoBlob(dataURL);

    let formData = new FormData();
    formData.append('image', blob, imageName);

    try {
      const res = await onSubmit(!!image ? formData : false);
      if (callback) callback(res);
    } catch (e) {
      await alertByError(e);
    }
  }

  async function handleDelete() {
    const result = await alertConfirm({text: deleteConfirmText});
    if (result) setImage('');
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={'ukas-confirm-modal'}
      {...rest}
    >
      <ModalHeader toggle={toggle}>
        {header ? header : 'Upload Photo'}
      </ModalHeader>

      <ModalBody>
        {isOpen && (
          <Row>
            <Col sm="12" md="auto" className="mb-3">
              <Dropzone
                onDrop={handleDrop}
                accept="image/*"
                disableClick
                style={{
                  width: `${width + border * 2}px`,
                  height: `${height + border * 2}px`,
                }}
              >
                <ReactAvatarEditor
                  className="editor-canvas"
                  crossOrigin="anonymous"
                  ref={handleRef}
                  image={image}
                  border={border}
                  scale={scale}
                  width={width}
                  height={height}
                  position={position}
                  onPositionChange={handlePositionChange}
                  rotate={rotate}
                  borderRadius={width / (100 / borderRadius)}
                />
              </Dropzone>

              {deleteText && fileToImageUrl(defaultImage, 'origin') && (
                <Button
                  outline
                  color="white"
                  className="text-danger d-block mx-auto"
                  onClick={() => handleDelete()}
                >
                  {deleteText}
                </Button>
              )}
            </Col>
            <Col>
              <fieldset key={isLoading} disabled={isSubmitting}>
                <FormGroup>
                  <Input
                    id="file"
                    type="file"
                    name="file"
                    accept={onlyAcceptedImage}
                    onChange={handleNewImage}
                  />
                  <FormText color="muted">
                    <p className="mb-0">
                      Supported file types:
                      {supportedImages}
                    </p>
                    <p className="mb-0">Max file size: {fileSizeLimit}MB</p>
                    {fileDimension && (
                      <p>Recommended dimensions: {fileDimension} px</p>
                    )}
                  </FormText>
                </FormGroup>
                <FormGroup row className="align-items-center">
                  <Label for="zoom" sm={12} md={3}>
                    Zoom
                  </Label>
                  <Col>
                    <CustomInput
                      id="zoom"
                      type="range"
                      onChange={handleScale}
                      min="0.1"
                      max="2"
                      step="0.1"
                      defaultValue="1"
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={12} md={3}>
                    Rotate
                  </Label>

                  <Col sm={12} md={9}>
                    <Row className="mx-n2">
                      <Col sm={12} md={6} className="px-1">
                        <Button
                          size="sm"
                          color="white"
                          block
                          onClick={handleRotateLeft}
                        >
                          <Icon
                            type="tio"
                            append="Left"
                            icon="image-rotate-left"
                          />
                        </Button>
                      </Col>

                      <Col sm={12} md={6} className="px-1">
                        <Button
                          size="sm"
                          color="white"
                          block
                          onClick={handleRotateRight}
                        >
                          <Icon
                            type="tio"
                            append="Right"
                            icon="image-rotate-right"
                          />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
              </fieldset>
            </Col>
          </Row>
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="white" onClick={() => toggle()} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          style={{minWidth: '120px'}}
          disabled={isSubmitting || !isNewImage}
          onClick={handleSave}
        >
          {isSubmitting ? 'Saving...' : submitText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default FormImageEditorModal;
