/**
 * https://www.theiconof.com/search
 * https://htmlstream.com/front-dashboard/documentation/icon-set.html
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

TIOIcon.propTypes = {
  icon: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['lg', 'xl']),
};

function TIOIcon(props) {
  const {icon, size, className, ...rest} = props;
  return (
    <i
      className={cx(`tio-${icon}`, className, {
        [`tio-${size}`]: size,
      })}
      {...rest}
    />
  );
}

export default TIOIcon;
