import React from 'react';
import cx from 'classnames';

CardContentHeaderTitle.defaultProps = {
  tag: 'h4',
};

function CardContentHeaderTitle(props) {
  const {tag, className, children, ...rest} = props;

  return React.createElement(
    tag,
    {
      className: cx('card-header-title text-dark', className),
      ...rest,
    },
    children
  );
}

export default CardContentHeaderTitle;
