import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'reactstrap';
import {formSubmit} from 'utils/forms';

DashboardForm.propTypes = {
  onSubmit: PropTypes.func,
  submitOptions: PropTypes.shape({hash: PropTypes.bool, empty: PropTypes.bool}),
};

DashboardForm.defaultProps = {
  noValidate: true,
};

function DashboardForm(props) {
  const {onSubmit, submitOptions, ...rest} = props;

  return <Form onSubmit={formSubmit(onSubmit, submitOptions)} {...rest} />;
}

export default DashboardForm;
