import React, {Children} from 'react'; //https://legacy.reactjs.org/docs/react-api.html#reactchildren
import {isEmpty} from 'lodash/lang';
import PropTypes from 'prop-types';
import {ImageEmptyData} from '../Images';

DashboardTableBody.propTypes = {
  emptyText: PropTypes.string,
};

DashboardTableBody.defaultProps = {
  emptyText: 'No data to display',
};

function DashboardTableBody(props) {
  const {isLoading, emptyText, children} = props;

  const _children = Children.toArray(children);

  return (
    <tbody>
      {!isLoading && isEmpty(_children) ? ( // checks if children -> array is empty
        <tr>
          <td colSpan="100">
            <div className="text-center py-5">
              <ImageEmptyData style={{width: '10rem'}} />
              <p className="mt-5">{emptyText}</p>
            </div>
          </td>
        </tr>
      ) : (
        !isLoading && children
      )}
    </tbody>
  );
}

export default DashboardTableBody;
