import React from 'react';
import {Label} from 'reactstrap';

function FormLabel(props) {
  const {required, labelFor, text, ...rest} = props;
  const _required = <span className="text-danger ml-1">*</span>;

  return (
    <Label for={labelFor} {...rest}>
      {text}
      {required && _required}
    </Label>
  );
}

export default FormLabel;
