import {CardContentHeader} from 'components/Cards';
import React, {Fragment, useEffect, useState} from 'react';
import cx from 'classnames';
import {Icon} from 'components/Icons';
import {InputClearable} from 'components/Inputs';
import {keys, last} from 'lodash';

DayPickerHeader.defaultProps = {
  names: [],
  placeholder: [],
  isAutoFocus: {},
  onClear: () => {},
};

const initialFocus = {
  start_date: true,
  end_date: false,
};

function DayPickerHeader(props) {
  const {names, range, placeholder, onClear, isOpen} = props;

  const [isAutoFocus, setIsAutoFocus] = useState(initialFocus);

  useEffect(() => {
    if (range) {
      if (range.length === 1)
        return setIsAutoFocus({start_date: false, end_date: true});
      if (range.length === 2)
        return setIsAutoFocus({start_date: false, end_date: false});
      setIsAutoFocus(initialFocus);
    }
  }, [range]);

  useEffect(() => {
    if (isOpen) {
      const inputs = names.map((v) => ({
        element: document.getElementById(v),
        name: v,
      }));

      inputs.forEach((input) => {
        const {name, element} = input || {};
        if (isAutoFocus[name]) {
          element.focus();
        }
      });
      return;
    }
    setIsAutoFocus(initialFocus);
  }, [isOpen, isAutoFocus]);

  const hasValues = keys(isAutoFocus).some((v) => isAutoFocus[v]);

  return (
    <CardContentHeader className="d-flex" style={{gap: '0.5rem'}}>
      {(names || []).map((name, index) => {
        const isLast = last(names) === name;

        return (
          <Fragment key={name + isAutoFocus[name]}>
            <Icon
              size="xs"
              type="fa"
              icon="minus"
              className={cx('align-self-center', {
                'd-none': !isLast,
              })}
            />

            <InputClearable
              id={name}
              key={name}
              name={name}
              keyValue={range || isAutoFocus[name]}
              defaultValue={(range || [])[index]}
              placeholder={placeholder[index]}
              onClear={() => onClear(index)}
              className={cx('text-center', {
                'bg-light': !isAutoFocus[name] && hasValues,
              })}
              style={{cursor: 'pointer'}}
              readOnly
            />
          </Fragment>
        );
      })}
    </CardContentHeader>
  );
}

export default DayPickerHeader;
