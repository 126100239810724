import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'reactstrap';
import {formValidate, formSerialize} from 'utils/forms';
import {DashboardButton} from 'components/Dashboard';
import {
  CardContent,
  CardContentBody,
  CardContentFooter,
} from 'components/Cards';
import {Icon} from 'components/Icons';

DashboardStepFormContent.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  show: PropTypes.bool,
  onPrev: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

DashboardStepFormContent.defaultProps = {
  isLoading: false,
  disabled: false,
  show: true,
  prevButtonText: 'Previous step',
  nextButtonText: 'Continue',
};

function DashboardStepFormContent(props) {
  const {
    show,
    onPrev,
    onNext,
    isLoading,
    disabled,
    nextButtonText,
    prevButtonText,
    children,
  } = props;

  let ref = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();

    const form = ref.current;

    if (formValidate(form)) {
      const formData = formSerialize(form);
      onNext(formData);
    }
  }

  return (
    <Form
      innerRef={ref}
      onSubmit={handleSubmit}
      noValidate
      className={show ? 'd-block' : 'd-none'}
    >
      <CardContent>
        <CardContentBody>{children}</CardContentBody>

        <CardContentFooter className="text-right">
          {onPrev && (
            <DashboardButton
              color="white"
              onClick={onPrev}
              className="mr-3"
              disabled={disabled}
            >
              <Icon type="tio" append={prevButtonText} icon="chevron-left" />
            </DashboardButton>
          )}

          {onNext && (
            <DashboardButton
              color="primary"
              onClick={handleSubmit}
              disabled={disabled}
              isLoading={isLoading}
            >
              <Icon type="tio" prepend={nextButtonText} icon="chevron-right" />
            </DashboardButton>
          )}
        </CardContentFooter>
      </CardContent>
    </Form>
  );
}

export default DashboardStepFormContent;
