import {every, first, isEmpty, isEqual, size, uniq} from 'lodash';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {DateObject} from 'react-multi-date-picker';
import mtz from 'moment-timezone';
import cleanDeep from 'clean-deep';

// convert into readle month format with current year
function convertMonths(currentMonth, shorthand = true) {
  const locale = shorthand ? 'MMM' : 'MMMM';
  return moment().month(currentMonth).format(locale);
}

function parseDateObj(dates, isMonthly) {
  return (dates || []).map((date) => {
    const {day, month, year} = date || {};
    const {index} = month || {}; // number, name

    const newDate = new Date(year, index, day);

    return isMonthly
      ? convertMonths(index).concat(` ${year}`)
      : moment(newDate).format('YYYY-MM-DD');
  });
}

// conversion of queryValues (dates) for into DateObject format
function convertDateToObj(dates, isMonthly) {
  return (dates || []).map((date) => {
    return new DateObject({
      date: isMonthly
        ? moment(date, 'MMMM YYYY').toDate()
        : moment(date).toDate(),
      format: 'MM/DD/YYYY',
    });
  });
}

function toUnixWithOffset(date) {
  const unix = moment(new Date(date)).unix();
  const offset = moment.tz(mtz.tz.guess()).utcOffset() / 60;
  return moment.unix(unix).subtract(offset, 'hours').unix();
}

function useRangePicker(props) {
  const {
    defaultValue,
    isMonthly = false,
    onConfirm,
    onToggle,
    acceptSameDate,
    isUnix,
  } = props;

  const [dateObjs, setDateObjs] = useState(); // obtaining dateObjs
  const [range, setRange] = useState([]); // formatted dates

  // defaultValues
  useEffect(() => {
    if (defaultValue) {
      setRange(defaultValue);
      // convert into DateObject for Calendar
      setDateObjs(convertDateToObj(defaultValue, isMonthly));
    }
  }, [defaultValue]);

  // when dateObjs, parse into readable format
  useEffect(() => {
    if (dateObjs) {
      setRange(parseDateObj(dateObjs, isMonthly));
    }
  }, [dateObjs]);

  // clear specific dates
  function handleClearDate(key) {
    if (key === 0) return setDateObjs([]);
    return setDateObjs((prevObjs) => prevObjs.slice(0, key));
  }

  // clear all dates
  function clearDates() {
    setRange([]);
    setDateObjs([]);
  }

  // onChange
  function handleChange(objs) {
    // checks if the unix of 2 dates are same if so clear end date to prevent passing same dates
    if (objs.length === 2 && !acceptSameDate) {
      const unixTime = uniq(objs.map((v) => new DateObject(v).unix));

      if (unixTime.length === 1) {
        return handleClearDate(1);
      }

      setDateObjs(objs);
    }

    setDateObjs(objs);
  }

  // apply func
  function handleApply() {
    onConfirm(isUnix ? range.map((v) => toUnixWithOffset(v)) : range);
    onToggle(); // dropdown toggle
  }

  return {
    handleClearDate,
    clearDates,
    handleChange,
    handleApply,
    range,
    dateObjs,
    isRangeDisabled:
      isEqual(size(range), 1) && !isEmpty(cleanDeep(defaultValue))
        ? every(defaultValue, (item) => isEqual(first(range), item))
        : isEqual(range, defaultValue),
  };
}

export default useRangePicker;
