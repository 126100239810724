import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import {noop} from 'lodash/util';
import {Button, Form} from 'reactstrap';

const propTypes = {
  submitText: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  reset: PropTypes.bool,
  serializeOptions: PropTypes.object,
};

const defaultProps = {
  submitText: ['Submit', 'Submitting...'],
  disabled: false,
  callback: noop,
  reset: false,
  serializeOptions: {hash: true, empty: false},
};

function FormSubmit({
  isLoading,
  isSubmitting,
  children,
  onSubmit,
  callback,
  showSubmit,
  submitText,
  description,
  reset,
  serializeOptions,
  ...rest
}) {
  async function handleSubmit(e) {
    e.preventDefault();

    const form = e.target;

    // form.checkValidity
    // Returns true if form contains valid data.
    if (form.checkValidity() === false) {
      form.classList.add('was-validated');
      return false;
    }

    const formData = serialize(form, serializeOptions);

    const res = await onSubmit(formData);
    if (res) callback(res);
  }

  const [submit, submitting] = submitText;

  return (
    <Form
      key={isLoading}
      onSubmit={(e) => handleSubmit(e)}
      noValidate
      {...rest}
    >
      {children}

      {showSubmit && <hr />}

      {description && (
        <p className="text-muted">
          By clicking submit below, IAF CertSearch will send an activation link
          to the certification body email. The email holder is required to click
          on the link and sign up to activate the certification body.
        </p>
      )}

      {showSubmit && (
        <Button type="submit" color="primary" block disabled={isSubmitting}>
          {isSubmitting ? submitting : submit}
        </Button>
      )}
    </Form>
  );
}

FormSubmit.propTypes = propTypes;
FormSubmit.defaultProps = defaultProps;

export default FormSubmit;
