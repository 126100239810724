import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {lowerCase} from 'lodash/string';
import useQuery from 'hooks/useQuery';
import FilterColumn from './FilterColumn';
import cx from 'classnames';

FilterColumnQuery.propTypes = {
  toggle: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

function FilterColumnQuery(props) {
  const {value, children, toggle, selected, isSticky, disabled, ...rest} =
    props;
  const {query, updateQuery} = useQuery();
  const {order_by, order} = query;
  const [style, setStyle] = useState(`sorting_${lowerCase(order)}`);
  // '' = no sort 'asc' = ascending 'desc' descending

  function handleChange() {
    toggle(value);

    if (order_by !== value) {
      setStyle('sorting_asc');
      updateQuery({order_by: value, order: 'DESC'});
    } else {
      if (!order) {
        setStyle('sorting_asc');
        updateQuery({order_by: value, order: 'DESC'});
      } else if (order === 'DESC') {
        setStyle('sorting_desc');
        updateQuery({order_by: value, order: 'ASC'});
      } else if (order === 'ASC') {
        setStyle('sorting');
        updateQuery({order_by: '', order: ''});
      }
    }
  }

  return (
    <FilterColumn
      className={cx(order_by === value ? style : 'sorting')}
      style={{position: isSticky ? 'sticky' : 'relative', left: 0}}
      handleChange={() => {
        !disabled && handleChange();
      }}
      {...rest}
    >
      {children}
    </FilterColumn>
  );
}

export default FilterColumnQuery;
