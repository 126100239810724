import React from 'react';
import {CardFooter} from 'reactstrap';

function CardContentFooter(props) {
  const {...rest} = props;

  return <CardFooter {...rest} />;
}

export default CardContentFooter;
