import React from 'react';
import cx from 'classnames';
import {Container} from 'reactstrap';

DashboardContainer.defaultProps = {
  fluid: true,
};

function DashboardContainer(props) {
  const {fluid, className, children} = props;

  return (
    <Container className={cx('px-3 my-5 px-lg-5', className)} fluid={fluid}>
      {children}
    </Container>
  );
}

export default DashboardContainer;
