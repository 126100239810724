import React from 'react';
import {Button} from 'reactstrap';
import {Icon} from 'components/Icons';
import {CardContentBody} from 'components/Cards';
import {Calendar, DateObject} from 'react-multi-date-picker';

DatePickerCalendar.defaultProps = {
  onChange: () => {},
};

function customButton(direction, handleClick) {
  return (
    <Button
      color="white"
      onClick={handleClick}
      className="border-0"
      outline
      size="xs"
    >
      <Icon
        className="m-2"
        type="fa"
        icon={direction === 'right' ? 'chevron-right' : 'chevron-left'}
      />
    </Button>
  );
}

function DatePickerCalendar(props) {
  const {value, onChange, isRange, isMonthly, maxDate, minDate} = props; // keyValue = range, value = dateObjs

  return (
    <CardContentBody className="p-0">
      <Calendar
        value={value}
        // key={keyValue}
        renderButton={customButton}
        onlyMonthPicker={isMonthly}
        range={isRange || isMonthly}
        rangeHover={isRange || isMonthly}
        onChange={(objs) => onChange(objs)}
        style={{boxShadow: 'none', margin: 'auto'}}
        maxDate={
          isMonthly
            ? new DateObject({date: new Date()})
            : maxDate && new DateObject({date: new Date(maxDate)})
        }
        minDate={minDate && new DateObject({date: new Date(minDate)})}
      />
    </CardContentBody>
  );
}

export default DatePickerCalendar;
