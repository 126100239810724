import React from 'react';
import useQuery from 'hooks/useQuery';
import SelectInput from 'components/Selects/SelectInput';

function FilterSelectInput({name, ...rest}) {
  const {query, updateQuery} = useQuery();
  const value = query[name] || '';

  function handleChange(selected) {
    if (selected) {
      updateQuery({[name]: selected.value});
    } else {
      updateQuery({[name]: ''});
    }
  }

  return (
    <SelectInput
      onChange={handleChange}
      defaultValue={value}
      isClearable
      name={name}
      {...rest}
    />
  );
}

export default FilterSelectInput;
