import React from 'react';
import cx from 'classnames';
import {Icon} from 'components/Icons';

DashboardStepFormHeader.propTypes = {};

DashboardStepFormHeader.defaultProps = {
  current: 0,
};

function DashboardStepFormHeader(props) {
  const {current, steps, inline} = props;

  return (
    <ul
      className={cx('step step-md step-icon-sm mb-5', {
        'step-inline': inline,
      })}
    >
      {steps.map((item, index) => {
        return (
          <li key={index} className="step-item">
            <div className="step-content-wrapper">
              <span
                className={cx('step-icon', {
                  'step-icon-primary': index === current,
                  'step-icon-light': index > current,
                  'step-icon-soft-success': index < current,
                  'text-body': index !== current,
                })}
              >
                {index < current ? <Icon type="tio" icon="done" /> : index + 1}
              </span>

              <div className="step-content">
                <span
                  className={cx('step-title', {
                    'text-body': index !== current,
                  })}
                >
                  {item}
                </span>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}

export default DashboardStepFormHeader;
