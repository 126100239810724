import {Icon} from 'components/Icons';
import React, {Fragment} from 'react';
import {useState} from 'react';
import {Col, Row, Tooltip} from 'reactstrap';

const characters =
  '~ ` ! @ # $ % ^ & * ( ) _ - + = { [ } ] | \\ : ; “ ’ < , > . ? / ';

const validation = {
  uppercase: 'One uppercase letter(A-Z)',
  lowercase: 'One lowercase letter(a-z)',
  number: 'One number(0-9)',
  special: 'One special character',
  minimum: '12 characters minimum',
  maximum: '24 characters maximum',
};

function ValidationItem(props) {
  const {keyText, isSpecial, validation, states} = props;

  return (
    <div
      className={states[keyText] ? 'text-success' : 'text-danger'}
      style={{fontSize: '13px'}}
    >
      <Icon
        type="fa"
        icon={states[keyText] ? 'check' : 'times'}
        size="xs"
        key={keyText}
        id={isSpecial && 'icon'}
        className="mr-2"
      />
      <span id={isSpecial && 'specialChars'}>
        <Icon
          type="fa"
          icon={isSpecial && 'info-circle'}
          prepend={validation[keyText]}
        />
      </span>
    </div>
  );
}

function FieldPasswordDescription(props) {
  const {states} = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function toggleTooltip() {
    setTooltipOpen(!tooltipOpen);
  }

  return (
    <Row className="mb-3">
      <Col>
        {Object.keys(validation)
          .slice(0, 3)
          .map((key) => {
            return (
              <ValidationItem
                keyText={key}
                states={states}
                validation={validation}
                toggleTooltip={toggleTooltip}
                tooltipOpen={tooltipOpen}
              />
            );
          })}
      </Col>
      <Col>
        {Object.keys(validation)
          .slice(3, 6)
          .map((key) => {
            const isSpecial = key === 'special';

            return (
              <Fragment key={key}>
                <ValidationItem
                  keyText={key}
                  states={states}
                  isSpecial={isSpecial}
                  validation={validation}
                  toggleTooltip={toggleTooltip}
                  tooltipOpen={tooltipOpen}
                />
                {isSpecial && (
                  <Tooltip
                    placement="right"
                    isOpen={tooltipOpen}
                    toggle={toggleTooltip}
                    target="specialChars"
                    fade={false}
                  >
                    <span>{characters}</span>
                  </Tooltip>
                )}
              </Fragment>
            );
          })}
      </Col>
    </Row>
  );
}

export default FieldPasswordDescription;
