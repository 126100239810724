import React from 'react';
import {LocaleProvider} from './contexts/LocaleContext';
import {CaptchaProvider} from './contexts/CaptchaContext';
import {AuthProvider} from './contexts/AuthContext';
import {IdleTimerProvider} from './contexts/IdleTimerContext';
import {ViewportProvider} from './contexts/ViewportContext';
import {LayoutProvider} from './contexts/LayoutContext';
import {AlertProvider} from './contexts/AlertContext';
import {MaintenanceProvider} from './contexts/MaintenanceContext';
import {NotificationProvider} from './contexts/NotificationContext';

function AppProviders(props) {
  const {children} = props;

  return (
    <LocaleProvider>
      <CaptchaProvider>
        <AlertProvider>
          <AuthProvider>
            <IdleTimerProvider>
              <ViewportProvider>
                <LayoutProvider>
                  <MaintenanceProvider>
                    <NotificationProvider>{children}</NotificationProvider>
                  </MaintenanceProvider>
                </LayoutProvider>
              </ViewportProvider>
            </IdleTimerProvider>
          </AuthProvider>
        </AlertProvider>
      </CaptchaProvider>
    </LocaleProvider>
  );
}

export default AppProviders;
