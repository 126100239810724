import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelopeOpenText,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBook,
  faBuilding,
  faCertificate,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faCode,
  faCodeBranch,
  faCog,
  faCopy,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileCode,
  faFileExcel,
  faFileUpload,
  faFilter,
  faImage,
  faInfoCircle,
  faLandmark,
  faLock,
  faMinus,
  faPaperPlane,
  faPlus,
  faQrcode,
  faQuestionCircle,
  faSearch,
  faShieldAlt,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faStar,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUpload,
  faUser,
  faUsers,
  faUsersCog,
  faReply,
  faBellSlash,
  faClipboardCheck,
  faEnvelopeOpen,
  faBookmark,
  faDownload,
  faUserCircle,
  faGlobe,
  faMapPin,
  faBriefcase,
  faCommentDots,
  faChevronRight,
  faFile,
  faFileExport,
  faFileAlt,
  faChevronUp,
  faMinusCircle,
  faAdjust,
  faAngleDown,
  faAngleUp,
  faBell,
  faCaretDown,
  faCaretUp,
  faBars,
  faCircle,
  faImages,
  faChevronLeft,
  faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import {
  faYoutube,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
//import {far} from '@fortawesome/free-regular-svg-icons';  // Not free

library.add(
  faEnvelopeOpenText,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBook,
  faBuilding,
  faCertificate,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faCode,
  faCodeBranch,
  faCog,
  faCopy,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileCode,
  faFileExcel,
  faFileUpload,
  faFilter,
  faImage,
  faInfoCircle,
  faLandmark,
  faLock,
  faMinus,
  faPaperPlane,
  faPlus,
  faQrcode,
  faQuestionCircle,
  faSearch,
  faShieldAlt,
  faSignOutAlt,
  faSlidersH,
  faSpinner,
  faStar,
  faSync,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faUpload,
  faUser,
  faUsers,
  faUsersCog,
  faYoutube,
  faInfoCircle,
  faReply,
  faBellSlash,
  faClipboardCheck,
  faEnvelopeOpen,
  faBookmark,
  faDownload,
  faUserCircle,
  faGlobe,
  faMapPin,
  faBriefcase,
  faCommentDots,
  faChevronRight,
  faFile,
  faFileExport,
  faLinkedinIn,
  faTwitter,
  faFileAlt,
  faChevronUp,
  faMinusCircle,
  faAdjust,
  faAngleDown,
  faAngleUp,
  faBell,
  faCaretUp,
  faCaretDown,
  faBars,
  faCircle,
  faImages,
  faChevronLeft,
  faCalendar
);
