import http, {makeSearchParams} from 'utils/http';

export const addSearchAccountHistory = (data) => {
  return http('POST', `search/account-history`, data);
};

// UNUSED
export const getSearchSuggestCompany = (query) => {
  return http('GET', `search/suggest-company?q=${query}`);
};

// UNUSED
export const getSearchSuggestCertificate = (query) => {
  return http('GET', `search/suggest-certificate?q=${query}`);
};

export const getSearchLimit = (data) => {
  return http('GET', '/limit/counts', makeSearchParams(data));
};
