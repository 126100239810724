import http, {get, put, remove, makeSearchParams} from 'utils/http';

/**
 * Get CB's sectors list by AB account
 * @param cbId
 * @param queries
 */
export const getCBSchemeSectorByAB = (cbId, queries) => {
  return get(`cb-scheme-sector-by-ab/${cbId}`, queries);
};

/**
 * Delete CB's sector by sector id
 * @param sectorId
 * @returns {Promise<*>}
 */

// UNUSED
export const removeCBSchemeSectorById = (sectorId) => {
  return remove(`cb-scheme-sector/${sectorId}`);
};

/**
 *
 * @param sectorId
 * @param data
 * @returns {Promise<unknown>}
 */
export const putCBSchemeSector = (sectorId, data) => {
  return put(`cb-scheme-sector/${sectorId}`, data);
};

/**
 * Delete All Sectors under the CB
 * @param cbId
 * @returns {Promise<unknown>}
 */
export const deleteCBSchemeSectorByABById = (cbId) => {
  return remove(`cb-scheme-sector-by-ab/${cbId}`);
};

//
//
// IAF

/**
 * Get AB's sectors
 * @param queries
 */
export const getABSectors = (queries) => {
  return http('GET', `ab/ab-sectors`, makeSearchParams(queries));
};

export const getABSectorsCount = () => {
  return get(`/ab/ab-sectors/count`);
};

export const postABSectors = (data) => {
  return http('POST', `ab/ab-sectors`, data);
};

export const delABSector = (id) => {
  return http('DELETE', `ab/ab-sectors/${id}`);
};

export const delBulkABSector = (data) => {
  return http('DELETE', `ab/ab-sectors/bulk-delete`, data);
};

// UNUSED
export const getABCBSectors = (queries) => {
  return http('GET', 'sectors/search-in-cb', makeSearchParams(queries));
};

/**
 * Add AB's sectors
 * @param queries
 */

export const getGlobalSectors = (queries) => {
  return http('GET', 'sectors', makeSearchParams(queries));
};

export const getGlobalSectorsCount = (queries) => {
  return get(`sectors/count`, makeSearchParams(queries));
};

export const getSingleSector = (sectorId) => {
  return http('GET', `sectors/${sectorId}`);
};

/**
 * Add AB's sectors
 * @param data
 */

export const addABSector = (data) => {
  return http('POST', 'sectors', data);
};

export const addABSectorBulk = (data) => {
  return http('POST', 'sectors/bulk-assign', data);
};

/**
 * Update AB's sectors
 * @param sectorId
 */

export const updateABSector = (sectorId, data) => {
  return http('PUT', `sectors/${sectorId}`, data);
};

/**
 * Delete AB's sectors
 * @param sectorId
 */

export const deleteABSector = (sectorId) => {
  return http('DELETE', `sectors/${sectorId}`);
};

export const bulkDeleteABSector = (sectors) => {
  return http('DELETE', 'sectors', sectors);
};

/**
 * Get CB's sectors
 * @param queries
 */
// UNUSED
export const getCBSectorCount = () => {
  return http('GET', '/cb-scheme-sector/count');
};

export const getCBSectors = (queries) => {
  return http('GET', 'cb-scheme-sector', makeSearchParams(queries));
};

export const getCBSectorFilter = (queries) => {
  return http('GET', '/cb-scheme-sector/filter', makeSearchParams(queries));
};

// UNUSED
export const getCBSectorsByAB = (cbId, queries) => {
  return http(
    'GET',
    `cb-scheme-sector-by-ab/${cbId}`,
    makeSearchParams(queries)
  );
};

/**
 * Get CB's sectors by MNCB
 * @param cbId
 * @param queries
 */
// UNUSED
export const getCBSectorsByMNCB = (cbId, queries) => {
  return http(
    'GET',
    `cb-scheme-sector-by-mncb/${cbId}`,
    makeSearchParams(queries)
  );
};

/**
 * Add CB's sector by AB
 * @param cbId
 * @param data
 */
// UNUSED
export const addCBSectorsByAB = (cbId, data) => {
  return http('POST', `cb-scheme-sector-by-ab/${cbId}`, data);
};

export const addCBBulkSectorsByAB = (cbId, data) => {
  return http('POST', `cb-scheme-sector-by-ab/${cbId}/bulk-assign`, data);
};

export const addSector = (data) => {
  return http('POST', 'cb-scheme-sector', data);
};

// UNUSED
export const getSector = (sectorId) => {
  return http('GET', `cb-scheme-sector/${sectorId}`);
};

export const updateSector = (sectorId, data) => {
  return http('PUT', `cb-scheme-sector/${sectorId}`, data);
};

export const deleteSector = (sectorId) => {
  return http('DELETE', `cb-scheme-sector/${sectorId}`);
};

export const deleteBulkSectorsAB = (cbId, data) => {
  return http('DELETE', `/cb-scheme-sector-by-ab/${cbId}`, data);
};

export const deleteBulkSectorsCB = (data) => {
  return http('DELETE', '/cb-scheme-sector', data);
};

// UNUSED
export const getCBSchemeSector = (search) => {
  return http('GET', 'cb-scheme-sector/assign', makeSearchParams(search));
};

export const assignCBSchemeSector = (data) => {
  return http('POST', 'cb-scheme-sector/assign', data);
};

export const deleteCBSchemeSector = (id) => {
  return http('DELETE', `cb-scheme-sector/${id}`);
};

// AB - CB -> Technical Sectors
export const deleteCBSchemeSectorByAB = (cbId, schemeSectorId) => {
  return http('DELETE', `/cb-scheme-sector-by-ab/${cbId}/${schemeSectorId}`);
};

export const getCertsTechnicalSectors = (certId, queries) => {
  return http(
    'GET',
    `/certifications/${certId}/technical-sectors`,
    makeSearchParams(queries)
  );
};

/**
 * Sector Groups
 */

// UNUSED
export const getSectorGroupCount = () => {
  return http('GET', '/sectors-group/count');
};

export const getSectorsGroup = (queries) => {
  return http('GET', '/sectors-group', makeSearchParams(queries));
};

export const postSectorsGroup = (data) => {
  return http('POST', '/sectors-group', data);
};

export const deleteSectorsGroup = (id) => {
  return http('DELETE', `sectors-group/${id}`);
};

export const deleteBulkSectorsGroup = (data) => {
  return http('DELETE', '/sectors-group/bulk-delete', data);
};
