import React from 'react';
import PropTypes from 'prop-types';
import DatePickerCalendar from '../DatePickerCalendar';
import DatePickerActions from '../DatePickerActions';
import {CardContent} from 'components/Cards';
import DayPickerHeader from './DayPickerHeader';
import useRangePicker from '../useRangePicker';
import '../DatePickr.css';

DayPicker.propTypes = {
  names: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isRange: PropTypes.bool,
  acceptSameDate: PropTypes.bool,
};

DayPicker.defaultProps = {
  isRange: false,
  acceptSameDate: false,
};

function DayPicker(props) {
  const {
    isRange,
    names,
    placeholder,
    isOpen,
    onToggle,
    onConfirm,
    defaultValue,
    acceptSameDate,
    maxDate,
    minDate,
    isUnix,
  } = props;

  const {
    handleApply,
    clearDates,
    handleChange,
    handleClearDate,
    range,
    dateObjs,
    isRangeDisabled,
  } = useRangePicker({
    defaultValue,
    onConfirm,
    onToggle,
    acceptSameDate,
    isUnix,
  });

  return (
    <CardContent>
      <DayPickerHeader
        range={range}
        names={names}
        isOpen={isOpen}
        onClear={handleClearDate}
        placeholder={placeholder}
      />
      <DatePickerCalendar
        value={dateObjs}
        keyValue={range}
        onChange={handleChange}
        isRange={isRange}
        maxDate={maxDate}
        minDate={minDate}
      />
      <DatePickerActions
        range={range}
        onApply={handleApply}
        isDisabled={isRangeDisabled}
        acceptSameDate={acceptSameDate}
        onCancel={() => onToggle(clearDates)}
      />
    </CardContent>
  );
}

export default DayPicker;
