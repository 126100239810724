import http, {makeSearchParams} from 'utils/http';

export const getMembers = (data) => {
  return http('GET', 'members', makeSearchParams(data));
};

export const getMember = (id) => {
  return http('GET', `members/${id}`);
};

// UNUSED
export const sendMember = (data) => {
  return http('POST', 'members', data);
};

export const updateMember = (id, data) => {
  return http('PUT', `members/${id}`, data);
};

export const inviteMember = (data) => {
  return http('POST', `members/invite`, data);
};

export const resendMember = (id) => {
  return http('POST', `members/${id}/resend`);
};

export const deleteMember = (id) => {
  return http('DELETE', `members/${id}`);
};

export const deleteMembersPermanentDelete = (userId) => {
  return http('DELETE', `/members/permanent-delete/${userId}`);
};

// UNUSED
export const getRolePermission = () => {
  return http('GET', 'permissions');
};

export const getCompanyRoles = (queries) => {
  return http('GET', 'company-roles', makeSearchParams(queries));
};

export const deleteCompanyRole = (id) => {
  return http('DELETE', `company-roles/${id}`);
};

export const createCompanyRole = (data) => {
  return http('POST', `company-roles`, data);
};

export const updateCompanyRole = (id, data) => {
  return http('PUT', `company-roles/${id}`, data);
};

export const getDefaultPermission = () => {
  return http('GET', `permissions`);
};

export const getRoleDetails = (id) => {
  return http('GET', `company-roles/${id}`);
};

export const getCompanyRolesList = () => {
  return http('GET', 'company-roles/list');
};

export const getBranchRoleDetails = (companyRoleId, branchId) => {
  return http('GET', `branches/${branchId}/company-roles/${companyRoleId}`);
};

export const getBranchCompanyRolesList = (branchId) => {
  return http('GET', `branches/${branchId}/company-roles/list`);
};
