import React from 'react';

function FilterColumn(props) {
  const {children, className, handleChange, ...rest} = props;

  return (
    <th role="row" onClick={handleChange} className={className} {...rest}>
      {children}
    </th>
  );
}

export default FilterColumn;
