import React, {useState} from 'react';
import cx from 'classnames';
import {isEmpty, first} from 'lodash';
import {
  ButtonGroup,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import {Icon} from 'components/Icons';
import PropTypes from 'prop-types';

DashboardTableButton.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
      icon: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
      ]),
      content: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
      ]).isRequired,
    })
  ).isRequired,
  block: PropTypes.bool,
};

DashboardTableButton.defaultProps = {
  block: false,
};

function DashboardTableButton(props) {
  const {items, block} = props;
  const [isDropOpen, setIsDropOpen] = useState(false);

  function toggleDrop() {
    setIsDropOpen(!isDropOpen);
  }

  const firstItem = items.slice(0, 1);
  const {tag, icon, content, ...rest} = first(firstItem) || {};
  const restItem = items.slice(1);

  return (
    <ButtonGroup className={cx({'btn-block': block})}>
      <Button color="white" tag={tag || 'button'} {...rest}>
        {icon} {content}
      </Button>
      {!isEmpty(restItem) && (
        <ButtonDropdown isOpen={isDropOpen} toggle={toggleDrop}>
          <DropdownToggle color="white" className="rounded-right">
            <Icon type="fa" icon="ellipsis-h" />
          </DropdownToggle>
          <DropdownMenu container="body">
            {restItem.map((item, idx) => {
              const {content, tag, ...rest} = item;
              return (
                <DropdownItem key={idx} tag={tag || 'button'} {...rest}>
                  {content}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
      )}
    </ButtonGroup>
  );
}

export default DashboardTableButton;
