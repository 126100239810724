import {get, post} from 'utils/http';

/**
 * ----------------------------------------
 * Public APIs
 * Naming rule: method + 'Public' + API
 * e.g. GET 'g/some-data/Id' => getPublicSomeDataById
 * ----------------------------------------
 */

/**
 * @param params
 * @returns Array countries
 */
export const getPublicCountries = (params) => {
  return get('g/countries', params);
};

/**
 * @param params
 * @returns Array countries
 */
export const getPublicRegions = (params) => {
  return get('g/regions', params);
};

/**
 * Get company details by company ID
 * @param companyId
 * @returns company
 */
// UNUSED
export const getPublicCompanyById = (companyId) => {
  return get(`g/company/${companyId}`);
};

/**
 * Get industries
 * @param params
 * @returns industries
 */
export const getPublicIndustries = (params) => {
  return get(`g/industries`, params);
};

/**
 * @returns active_ab, active_certs, searchable_ab
 */
// UNUSED
export const getPublicLandingStats = () => {
  return get('g/landing/stats');
};

/**
 * @returns {Promise<unknown>}
 */
// UNUSED
export const getPublicLandingCountryStats = () => {
  return get('g/landing/country-stats');
};

/**
 * @param params
 * @returns schemes
 */
// UNUSED
export const getPublicSchemes = (params) => {
  return get(`g/schemes`, params);
};

/**
 * Get app details by app ID
 * @param appId
 * @returns app
 */
// UNUSED
export const getPublicAppById = (appId) => {
  return get(`g/app/${appId}`);
};

/**
 *
 * @param params
 * @returns schemes
 */
// UNUSED
export const getPublicCBSchemeList = (params) => {
  return get(`cb-scheme-list`, params);
};

/**
 * Get entity (company) details by entity ID
 * @param entityId
 * @returns entity
 */
// UNUSED / API DUPLICATE
export const getPublicRedirectById = (entityId) => {
  return get(`rd/${entityId}`);
};

/**
 * Get CSV by type
 * @param type
 * @returns csv
 */
// UNUSED / API DUPLICATE
export const getPublicCSVByType = (type) => {
  return get(`csv/${type}`);
};

/**
 *
 * @param data
 * @returns {Promise<unknown>}
 * @constructor
 */
// UNUSED / API DUPLICATE
export const postPublicQTSync = (data) => {
  const {app_id, consent} = data;
  return post(`qt/sync`, {app_id, consent});
};
