import React from 'react';
import {CardHeader} from 'reactstrap';

function CardContentHeader(props) {
  const {...rest} = props;

  return <CardHeader {...rest} />;
}

export default CardContentHeader;
