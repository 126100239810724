import React from 'react';
import cx from 'classnames';

DashboardActivity.propTypes = {};

function DashboardActivity(props) {
  const {children, className} = props;

  return <ul className={cx('step step-icon-xs', className)}>{children}</ul>;
}

export default DashboardActivity;
