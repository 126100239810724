import React, {useRef, useEffect, useState, useCallback} from 'react';
import {Col, FormGroup, Label, FormFeedback} from 'reactstrap';
import {InputPassword} from 'components/Inputs';
import {REGEX_FEEDBACK} from 'constants/regex';
import {debounce} from 'lodash';
import {postCheckPassword} from 'api/auth';
import {alertByError} from 'utils/alerts';
import FieldPasswordStrength from './FieldPasswordStrength';
import FieldPasswordDescription from './FieldPasswordDescription';
import FieldPasswordBreaches from './FieldPasswordBreaches';
import escapeStringRegexp from 'escape-string-regexp';

FieldPassword.defaultProps = {
  row: false,
  passwordName: 'password',
  confirmPasswordName: 'password_confirmation',
};

function PasswordFormField(props) {
  const {
    row = true,
    label,
    children,
    grid = row ? {sm: 12, lg: 4, xl: 3} : undefined,
    className,
  } = props;
  return (
    <FormGroup row={row} className={className}>
      {!!label && <Label {...grid}>{label}</Label>}

      <Col className={row ? '' : 'p-0'}>{children}</Col>
    </FormGroup>
  );
}
function FieldPassword(props) {
  const {
    row,
    passwordName,
    confirmPasswordName,
    passwordLabel,
    confirmPasswordLabel,
    passwordPlaceholder,
    confirmPasswordPlaceholder,
    inBreached,
    setInBreached,
  } = props;
  const confirmRef = useRef();
  const [password, setPassword] = useState('');
  const [regexPassword, setRegexPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPass, setIsValidPass] = useState(false);
  const [states, setStates] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    minimum: false,
    maximum: false,
  });

  useEffect(() => {
    if (confirmRef.current) {
      const inputGroup = confirmRef.current.closest('.input-group');

      if (password === confirmPassword) {
        inputGroup.classList.remove('is-invalid');
      } else {
        inputGroup.classList.add('is-invalid');
      }
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    setRegexPassword(escapeStringRegexp(password));
  }, [password]);

  async function checkPassword(input, isValid, target) {
    setIsLoading(true);
    try {
      const {data} = await postCheckPassword({password: input});
      const {password_breached} = data || {};

      if (!password_breached) {
        target.classList.remove('is-invalid');
      } else {
        target.classList.add('is-invalid');
      }

      setInBreached(password_breached);
      setIsValidPass(isValid && !password_breached);
      setIsLoading(false);
    } catch (e) {
      alertByError(e);
      setIsLoading(false);
    }
  }

  function onChange(value, isValid, target) {
    setPassword(value);
    checkPassword(value, isValid, target);
  }

  function handleChange(e) {
    e.preventDefault();

    const target = e.target;
    const {value} = target;

    const valid = [
      changeValidation(/[A-Z]/, 'uppercase', value),
      changeValidation(/[a-z]/, 'lowercase', value),
      changeValidation(/[0-9]/, 'number', value),
      changeValidation(
        // eslint-disable-next-line
        /[*@!#$%&()^~{}\-“’\_\=\+\[\]\;\:\'\"\<\>\.\,\/\?\`\~\|\\\s]/,
        'special',
        value
      ),
      changeValidation(/^.{12,}$/, 'minimum', value),
      changeValidation(/^.{0,24}$/, 'maximum', value),
    ];

    const isValid = valid.every((v) => v);
    const inputGroup = target.closest('.input-group');

    if (target.checkValidity()) {
      inputGroup.classList.remove('is-invalid');
    } else {
      inputGroup.classList.add('is-invalid');
    }

    onChange(value, isValid, target);
  }

  function changeValidation(regex, key, value) {
    if (regex.test(value)) {
      setStates((prevState) => {
        return {...prevState, [key]: true};
      });

      return true;
    } else {
      setStates((prevState) => {
        return {...prevState, [key]: false};
      });
      return false;
    }
  }

  const handleChangeDebounce = useCallback(debounce(handleChange, 500), []);

  useEffect(() => {
    return () => {
      handleChangeDebounce.cancel();
    };
  }, [handleChangeDebounce]);

  return (
    <div>
      <PasswordFormField row={row} label={passwordLabel}>
        <InputPassword
          name={passwordName}
          id="password"
          placeholder={passwordPlaceholder}
          onChange={handleChangeDebounce}
          required
          autoComplete="new-password"
        />
      </PasswordFormField>
      <PasswordFormField row={row} label={confirmPasswordLabel}>
        <InputPassword
          innerRef={confirmRef}
          name={confirmPasswordName}
          id="password_confirmation"
          placeholder={confirmPasswordPlaceholder}
          pattern={`^${regexPassword}$`}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          confirm={false}
          required
        />
        <FormFeedback>{REGEX_FEEDBACK.invalid_confirm_password}</FormFeedback>
      </PasswordFormField>
      <FieldPasswordStrength isLoading={isLoading} isValidPass={isValidPass} />
      <FieldPasswordDescription states={states} />
      <FieldPasswordBreaches isLoading={isLoading} inBreached={inBreached} />
    </div>
  );
}

export default FieldPassword;
