import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'reactstrap';
import {Icon} from 'components/Icons';
import cx from 'classnames';

DashboardHelperText.propTypes = {
  iconName: PropTypes.string,
  color: PropTypes.string,
  isClosable: PropTypes.bool,
};

DashboardHelperText.defaultProps = {
  iconName: 'exclamation-triangle',
  color: 'info',
  isClosable: false,
};

function DashboardHelperText({
  children,
  iconName,
  color,
  isClosable,
  className,
  ...rest
}) {
  const [visible, setVisible] = useState(true);

  return (
    <Alert
      className={cx(
        'd-flex align-items-center',
        'alert',
        {[`alert-soft-${color}`]: !!color},
        'border-0',
        className
      )}
      isOpen={visible}
      toggle={isClosable ? () => setVisible(false) : false}
      {...rest}
    >
      <div className="mr-2">
        <Icon type="fa" icon={iconName} />
      </div>
      <div className="flex-grow-">{children}</div>
    </Alert>
  );
}

export default DashboardHelperText;
