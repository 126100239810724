import React from 'react';
import useQuery from 'hooks/useQuery';
import {VersionSelect} from 'components/Selects';

function FilterSelectVersion({name, ...rest}) {
  const {query, updateQuery} = useQuery();
  const value = query[name] || '';

  function handleChange(selected) {
    const {standards_revision} = selected;

    if (standards_revision) {
      updateQuery({[name]: standards_revision});
    } else {
      updateQuery({[name]: ''});
    }
  }

  return (
    <VersionSelect
      onChange={handleChange}
      defaultValue={value && {[name]: value}}
      name={name}
      {...rest}
    />
  );
}

FilterSelectVersion.defaultProps = {
  name: 'standards_revision',
};

export default FilterSelectVersion;
