import React from 'react';
import PropTypes from 'prop-types';
import useQuery from 'hooks/useQuery';
import SelectInput from 'components/Selects/SelectInput';

FilterOrderBy.propTypes = {
  source: PropTypes.object.isRequired,
};

function FilterOrderBy({source, ...rest}) {
  const {query, updateQuery} = useQuery();
  const value = query.order_by || '';

  function handleChange(selected) {
    if (selected) {
      updateQuery({order_by: selected.value, order: 'ASC'});
    } else {
      updateQuery({order_by: '', order: ''});
    }
  }

  return (
    <SelectInput
      onChange={handleChange}
      defaultValue={value}
      isClearable
      source={source}
      name="order_by"
      {...rest}
    />
  );
}

export default FilterOrderBy;
