import React, {useRef} from 'react';
import ReactCookieConsent from 'react-cookie-consent';
import {encrypt} from 'utils/encrypts';
import {COOKIE_PREFIX, SECRET_KEY} from 'configs';
import {Container} from 'reactstrap';
import {LandingLink} from 'components/Landing';

const style = {
  boxShadow: '4px 2px 16px 0px rgba(179,172,179,1)',
  color: 'black',
  display: 'flex',
  alignItems: 'center',
  padding: '1rem 1rem',
  justifyContent: 'center',
  zIndex: '10000',
};

const cookieName = `${COOKIE_PREFIX}_consent`;
const cookieValue = encrypt(true, SECRET_KEY).toString();
const declineCookieValue = encrypt(false, SECRET_KEY).toString();

function CookieConsent() {
  const cookieConsentRef = useRef();

  return (
    <ReactCookieConsent
      ref={cookieConsentRef}
      // debug={true}
      hideOnAccept
      enableDeclineButton
      location="bottom"
      cookieName={cookieName}
      cookieValue={cookieValue}
      declineCookieValue={declineCookieValue}
      style={style}
      flipButtons={true}
      buttonText="Accept"
      buttonStyle={{display: 'none'}}
      declineButtonClasses="d-none"
      containerClasses="bg-primary text-white text-center"
    >
      <Container style={{maxWidth: '800px'}}>
        <p className="mb-0 text-left">
          We are using cookies to give you the best experience on our website.
          By clicking Accept you agree to the use of cookies.
        </p>

        <LandingLink
          text="Accept"
          theme="light"
          disabledHover
          style={{maxWidth: '200px'}}
          onClick={() => cookieConsentRef.current.accept(cookieConsentRef)}
        />
      </Container>
    </ReactCookieConsent>
  );
}

export {CookieConsent};
