import {Icon} from 'components/Icons';
import {TextLoading} from 'components/Loadings';
import React from 'react';

function FieldPasswordStrength(props) {
  const {isLoading, isValidPass} = props;

  return (
    <div className="d-flex align-items-center flex-row mb-2">
      <div>
        <span className="mr-2">Password Strength:</span>
      </div>
      {!isLoading ? (
        <div className={isValidPass ? 'text-success' : 'text-danger'}>
          <Icon
            type="tio"
            append={isValidPass ? 'Strong' : 'Weak'}
            icon={isValidPass ? 'security-on' : 'security-off'}
          />
        </div>
      ) : (
        <TextLoading isLoading className="mr-1" />
      )}
    </div>
  );
}

export default FieldPasswordStrength;
