import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Row, UncontrolledAlert} from 'reactstrap';
import DashboardStepFormHeader from './DashboardStepFormHeader';
import DashboardStepFormContent from './DashboardStepFormContent';
import {Icon} from 'components/Icons';

DashboardStepForm.propTypes = {
  steps: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  inline: PropTypes.bool.isRequired,
  alertMessages: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      message: PropTypes.string,
      alertProps: PropTypes.object,
      alertIconProps: PropTypes.object,
    })
  ),
};

DashboardStepForm.defaultProps = {
  inline: true,
};

function DashboardStepForm(props) {
  const {
    steps,
    onNext,
    onPrev,
    isSubmitting,
    onSubmit,
    inline,
    disabled,
    alertMessages,
    children,
  } = props;

  // Step form states
  const [current, setCurrent] = useState(0);

  // Scroll
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll
  }, [current]);

  // Next prev
  function handlePrev(formData) {
    if (onPrev) onPrev(formData, current);
    setCurrent(current - 1);
  }

  // Next step
  function handleNext(formData) {
    if (onNext) onNext(formData, current);
    setCurrent(current + 1);
  }

  return (
    <Row className="justify-content-center">
      <Col xl={8}>
        <DashboardStepFormHeader
          current={current}
          steps={steps}
          inline={inline}
        />

        {(alertMessages || []).map((am, index) => {
          const {step, message, alertProps, alertIconProps} = am;
          const _alertProps = alertProps || {
            color: 'soft-info',
            className: 'd-flex',
          };
          const _alertIconProps = alertIconProps || {
            type: 'fa',
            icon: 'info-circle',
            className: 'mt-1',
          };

          if (step !== current) return null;

          return (
            <UncontrolledAlert {..._alertProps} key={index}>
              <Icon {..._alertIconProps} />
              <div className="flex-grow-1 ml-2 text-justify">{message}</div>
            </UncontrolledAlert>
          );
        })}

        {(children || []).map((child, index) => {
          const isFirst = index <= 0;
          const isLast = index >= children.length - 1;

          let onPrev = isFirst ? null : handlePrev;
          let onNext = isLast ? onSubmit : handleNext;
          let nextButtonText = isLast ? 'Submit' : 'Continue';

          return (
            <DashboardStepFormContent
              key={index}
              show={current === index}
              onPrev={onPrev}
              onNext={onNext}
              nextButtonText={nextButtonText}
              isLoading={isSubmitting}
              disabled={disabled}
            >
              {child}
            </DashboardStepFormContent>
          );
        })}
      </Col>
    </Row>
  );
}

export default DashboardStepForm;
