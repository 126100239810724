import React from 'react';
import {useLocation, Link} from 'react-router-dom';
import urlJoin from 'url-join';
import {useCaptchaCode} from 'contexts/CaptchaContext';

function CaptchaAlternativeLink() {
  const location = useLocation();
  const {captchaCode} = useCaptchaCode();

  const pathname = location.pathname || window.location.pathname;
  const code = captchaCode === 'g' ? 'h' : 'g';

  const url = urlJoin(pathname, `?captcha=${code}`);

  return (
    <p className="mb-0">
      Captcha not working? Click <Link to={url}>here</Link>
    </p>
  );
}

export default CaptchaAlternativeLink;
