import {CardContent} from 'components/Cards';
import React from 'react';
import MonthPickerHeader from './MonthPickerHeader';
import DatePickerCalendar from '../DatePickerCalendar';
import DatePickerActions from '../DatePickerActions';
import useRangePicker from '../useRangePicker';
import PropTypes from 'prop-types';
import '../DatePickr.css';

MonthPicker.propTypes = {
  names: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isMonthly: PropTypes.bool,
};

MonthPicker.defaultProps = {
  isMonthly: false,
};

function MonthPicker(props) {
  const {
    isMonthly,
    names,
    placeholder,
    isOpen,
    onToggle,
    onConfirm,
    defaultValue,
  } = props;

  const {
    handleApply,
    clearDates,
    handleChange,
    handleClearDate,
    range,
    dateObjs,
  } = useRangePicker({defaultValue, isMonthly, onConfirm, onToggle});

  return (
    <CardContent>
      <MonthPickerHeader
        range={range}
        names={names}
        isOpen={isOpen}
        onClear={handleClearDate}
        placeholder={placeholder}
      />
      <DatePickerCalendar
        value={dateObjs}
        keyValue={range}
        onChange={handleChange}
        isMonthly={isMonthly}
      />
      <DatePickerActions
        range={range}
        onApply={handleApply}
        onCancel={() => onToggle(clearDates)}
      />
    </CardContent>
  );
}

export default MonthPicker;
