import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Avatar from './Avatar';

AvatarGroup.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg']),
  limit: PropTypes.number,
};

const defaultProps = {
  limit: 4,
};

function AvatarGroup(props) {
  const {size, items, limit, className} = props;
  const count = items.length;

  return (
    <div
      className={cx('avatar-group mb-1', {
        [size && `avatar-group-${size}`]: size,
        className,
      })}
    >
      {(items || []).splice(0, limit).map((item, index) => {
        return <Avatar key={index} {...item} />;
      })}

      {count > limit && (
        <Avatar color="primary">
          <span className={'avatar-initials'}>+{count - limit}</span>
        </Avatar>
      )}
    </div>
  );
}

AvatarGroup.defaultProps = defaultProps;

export default AvatarGroup;
