import styles from './CardContent.module.scss';
import React from 'react';
import cx from 'classnames';
import {Card} from 'reactstrap';

CardContent.defaultProps = {
  bordered: true,
};

function CardContent(props) {
  const {className, bordered, ...rest} = props;

  return (
    <Card
      className={cx(styles['card-content'], className, {
        'card-bordered': bordered,
      })}
      {...rest}
    />
  );
}

export default CardContent;
