import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {has, isObject, kebabCase, last} from 'lodash';
import {FilterColumnQuery, FilterColumnStateful} from 'components/Filters';
import {TextLoading} from 'components/Loadings';
import {Icon} from 'components/Icons';
import {UncontrolledTooltip} from 'reactstrap';

DashboardTableHeader.propTypes = {
  heads: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({label: PropTypes.string, value: PropTypes.string}),
      PropTypes.shape({isChecked: PropTypes.bool, action: PropTypes.func}),
    ])
  ).isRequired,
  isSticky: PropTypes.bool,
  isLoading: PropTypes.bool,
};

DashboardTableHeader.defaultProps = {
  isSticky: false,
  isLoading: false,
  isStickyLast: true,
};

function FilterColumnText({infoText, infoTexts, label, selected, index}) {
  const hasInfo = infoText || infoTexts; // single tooltip || tooltips based on tab

  const id = kebabCase(label + index + selected);

  return hasInfo ? (
    <>
      <UncontrolledTooltip
        fade={false}
        placement="top"
        target={id}
        className="flex-nowrap"
        style={{
          maxWidth: '25rem',
          textAlign: 'left',
        }}
      >
        <small>{infoText || infoTexts[selected]}</small>
      </UncontrolledTooltip>
      <Icon
        type="fa"
        icon="info-circle"
        className="text-primary"
        id={id}
        prepend={label}
      />
    </>
  ) : (
    label
  );
}

function DashboardTableHeader({
  heads,
  isLoading,
  isSticky,
  isNoQuery,
  onChange,
  search,
  disabled,
  isStickyLast,
  ...rest
}) {
  const [selected, setSelected] = useState('');

  if (isLoading) {
    return (
      <thead className="thead-light">
        <tr role="row">
          <th>
            <div className="d-flex justify-content-center">
              <TextLoading isLoading />
            </div>
          </th>
        </tr>
      </thead>
    );
  }

  return (
    <thead className="thead-light">
      <tr role="row" style={{zIndex: 100}}>
        {heads.map((item, index) => {
          const {label, value, infoText, infoTexts, isChecked, action, tab} =
            item || {};

          if (isObject(item) && has(item, 'value')) {
            return isNoQuery ? (
              <FilterColumnStateful
                toggle={setSelected}
                selected={selected}
                value={value}
                key={index + (!!search && search)}
                onChange={onChange}
                search={search}
                isSticky={isSticky}
                disabled={disabled}
              >
                <FilterColumnText
                  infoText={infoText}
                  infoTexts={infoTexts}
                  label={label}
                  selected={tab}
                  index={index}
                />
              </FilterColumnStateful>
            ) : (
              <FilterColumnQuery
                toggle={setSelected}
                selected={selected}
                value={value}
                key={index + (!!search && search)}
                isSticky={isSticky}
                disabled={disabled}
              >
                <FilterColumnText
                  infoText={infoText}
                  infoTexts={infoTexts}
                  label={label}
                  selected={tab}
                  index={index}
                />
              </FilterColumnQuery>
            );
          } else if (isObject(item) && has(item, 'isChecked')) {
            return (
              <th role="row" key={index}>
                <input type="checkbox" checked={isChecked} onChange={action} />
              </th>
            );
          } else {
            const isLast = last(heads) === item;

            return (
              <th
                role="row"
                key={index}
                style={{
                  position:
                    (isLast || isSticky) && isStickyLast
                      ? 'sticky'
                      : 'relative',
                  right: 0,
                  top: 0,
                  zIndex: 2,
                }}
              >
                <FilterColumnText
                  infoText={infoText}
                  infoTexts={infoTexts}
                  label={label || item}
                  selected={tab}
                  index={index}
                />
              </th>
            );
          }
        })}
      </tr>
    </thead>
  );
}

export default DashboardTableHeader;
