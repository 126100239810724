import React from 'react';
import {CardBody} from 'reactstrap';

function CardContentBody(props) {
  const {...rest} = props;

  return <CardBody {...rest} />;
}

export default CardContentBody;
